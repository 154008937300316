import { useQuery } from '@apollo/client';
import { CLASSROOM_CONFIGURATION_FEATURES_QUERY } from './gql';
import { ClassroomConfigurationFeaturesQuery } from './generatedTypes/ClassroomConfigurationFeaturesQuery';

export const useConfiguration = () => {
  const { data } = useQuery<ClassroomConfigurationFeaturesQuery>(
    CLASSROOM_CONFIGURATION_FEATURES_QUERY,
  );
  return data?.configuration;
};

export const useFeatures = () => {
  const configuration = useConfiguration();
  return configuration?.features;
};

export const useSettings = () => {
  const configuration = useConfiguration();
  return configuration?.settings;
};

export const usePartnerTheme = () => {
  const configuration = useConfiguration();
  return configuration?.theme;
};
