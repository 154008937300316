import { gql } from '@apollo/client';

export const CONFIG_QUERY = gql`
  query ConfigQueryV2 {
    configuration {
      id
      slug
      country {
        id
        currency {
          id
          isoCode
        }
      }
      settings {
        productTourWorkspaceCode: setting(key: "product_tour.workspace_code")
        homepageRedirect: setting(key: "homepage.redirect")
        title: setting(key: "title")
        parentUrl: setting(key: "parent.url")
        muxDataEnvKey: setting(key: "mux.data.env.key")
        gtmContainerId: setting(key: "gtm.container.id")
        gtmContainerParams: setting(key: "gtm.container.params")
        intercomAppId: setting(key: "intercom.app.id")
        helpscoutAppId: setting(key: "helpscout.app.id")
        frontendUrl: setting(key: "frontend.url")
        tapfiliateId: setting(key: "tapfiliate.id")
        klaviyoApiKey: setting(key: "klaviyo.api.key")
      }
      features {
        id
        homepage: feature(name: "homepage")
        giftcards: feature(name: "giftcards")
        courses: feature(name: "courses")
        experts: feature(name: "experts")
        profiles: feature(name: "profiles")
        schools: feature(name: "schools")
        shop: feature(name: "shop")
        search: feature(name: "search")
        intercom: feature(name: "intercom")
        helpscout: feature(name: "helpscout")
        about: feature(name: "about")
        landings: feature(name: "landings")
        facebook: feature(name: "facebook")
        signup: feature(name: "signup")
      }
    }
  }
`;
