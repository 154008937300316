import env from '@lwe/toolkit/env';

import genImgSrcParams, { DimentionsType } from './genImgSrcParams';

const generateSrc = ({ w, h }: DimentionsType): string => [`${w}`, h && `x${h}`].join('');

type ImageSrcType = {
  src?: string;
  srcSet?: string;
};

const getAssetImgSrc = (uuid: string | undefined, width: number, height?: number): ImageSrcType => {
  if (uuid) {
    const urlPrefix = `${env('ASSETS_ENDPOINT')}/${uuid}`;
    const { base, quarter, half, retina } = genImgSrcParams(width, height);

    const url = `${urlPrefix}/${generateSrc(base.dimentions)}`;
    const retinaUrl = `${urlPrefix}/${generateSrc(retina.dimentions)}`;
    const halfUrl = `${urlPrefix}/${generateSrc(half.dimentions)}`;
    const quarterUrl = `${urlPrefix}/${generateSrc(quarter.dimentions)}`;

    return {
      src: url,
      srcSet: `${quarterUrl} ${quarter.maxWidth}, ${halfUrl} ${half.maxWidth}, ${url} ${base.maxWidth}, ${retinaUrl} ${retina.maxWidth}`,
    };
  }

  return { src: undefined, srcSet: undefined };
};

export default getAssetImgSrc;
