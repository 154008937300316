import {
  gql,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  NormalizedCacheObject,
  DefaultOptions,
  ApolloLink,
  ServerError,
} from '@apollo/client';
import { Location } from 'react-router-v3';
import typePolicies from '../graphql/typePolicies';
import fragmentTypes from '../fragmentTypes.json';
import { onError } from '@apollo/client/link/error';

const INITIAL_DATA = gql`
  query InitialServerData {
    isMobile @client
  }
`;

type InitSsrClientPropsType = {
  isMobile?: boolean;
  origin?: string;
  apiEndpoint: string;
  countryCode?: string;
  timeTravel?: string;
  fetch?: (input: RequestInfo | URL, init?: RequestInit | undefined) => Promise<Response>;
  location: Location;
};

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'no-cache',
  },
};

const initSsrClient = ({
  isMobile,
  origin,
  apiEndpoint,
  countryCode,
  timeTravel,
  fetch: f,
  location,
}: InitSsrClientPropsType): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({
    uri: `${apiEndpoint}/graphql`,
    credentials: 'same-origin',
    fetch: f ?? fetch,
    headers: {
      ...(origin && { Origin: origin }),
      ...(countryCode && { 'X-Country': countryCode }),
      ...(timeTravel && { 'X-Time-Travel': timeTravel }),
    },
  });

  const errorLink = onError(({ networkError }) => {
    if (networkError) {
      location.SSR_HTTP_RESPONSE_CODE = (networkError as ServerError)?.statusCode;
    }
  });

  const cache = new InMemoryCache({
    possibleTypes: fragmentTypes.possibleTypes,
    typePolicies,
  });

  cache.writeQuery({
    query: INITIAL_DATA,
    data: {
      isMobile,
    },
  });

  return new ApolloClient({
    ssrMode: true,
    link: ApolloLink.from([errorLink, httpLink]),
    cache,
    defaultOptions,
  });
};

export default initSsrClient;
