export type DimentionsType = {
  w: number;
  h?: number;
};

const generateDimentions = (width: number, height?: number, multiplier = 1): DimentionsType => {
  return {
    w: Math.floor(width * multiplier),
    h: height ? Math.floor(height * multiplier) : undefined,
  };
};

type SourcePairType = {
  dimentions: DimentionsType;
  maxWidth: string;
};
type ReturnType = {
  quarter: SourcePairType;
  half: SourcePairType;
  base: SourcePairType;
  retina: SourcePairType;
};

const genImgSrcParams = (width: number, height?: number): ReturnType => {
  return {
    quarter: {
      dimentions: generateDimentions(width, height, 0.25),
      maxWidth: `${Math.floor(width / 4)}w`,
    },
    half: {
      dimentions: generateDimentions(width, height, 0.5),
      maxWidth: `${Math.floor(width / 2)}w`,
    },
    base: { dimentions: generateDimentions(width, height), maxWidth: `${width}w` },
    retina: { dimentions: generateDimentions(width, height, 2), maxWidth: `${width * 2}w` },
  };
};

export default genImgSrcParams;
