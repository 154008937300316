import { TextExtendedProps } from 'grommet';
import { darken, lighten } from 'polished';
import { css, FlattenSimpleInterpolation } from 'styled-components';

import { CheckboxThemableTProps, TDropThemableProps } from '../types/grommet';
import { getGlobalColor, getTransformOriginStyle } from '../utils';

export default {
  global: {
    'tint-light': 0.075,
    'tint-dark': 0.075,
    logo: {
      web: '48c6bfc4-c784-4312-9bff-3b3f6727ec2f',
      email: '48c6bfc4-c784-4312-9bff-3b3f6727ec2f',
    },
    logoUuid: '',
    colors: {
      brand: 'control',
      'dashboard-header': '#4A4A4A',
      'classroom-header': '#4A4A4A',
      'accent-1': '#C3C682',
      'accent-2': '#88C7E2',
      'accent-3': '#EBC882',
      'accent-4': '#E8ACBF',
      'neutral-1': '#99DBCE',
      'neutral-2': '#EBC882',
      'neutral-3': '#A1CAD1',
      'neutral-4': '#88C7E2',
      'neutral-5': '#E8ACBF',
      'neutral-6': '#E4A29E',
      'neutral-7': '#C3C682',
      'neutral-8': '#B7A1CC',

      'grey-1': { light: '#FFFFFF', dark: '#1C1C1C' },
      'grey-2': { light: '#FBFBFB', dark: '#282828' },
      'grey-3': { light: '#EFEFEF', dark: '#343434' },
      'grey-4': { light: '#E0E0E0', dark: '#4A4A4A' },
      'grey-5': { light: '#BEBEBE', dark: '#6E6E6E' },
      'grey-6': { light: '#929292', dark: '#929292' },
      'grey-7': { light: '#6E6E6E', dark: '#BEBEBE' },
      'grey-8': { light: '#4A4A4A', dark: '#E0E0E0' },
      'grey-9': { light: '#343434', dark: '#EFEFEF' },
      'grey-10': { light: '#282828', dark: '#F9F9F9' },
      'grey-11': { light: '#1C1C1C', dark: '#FBFBFB' },
      'grey-12': { light: '#000000', dark: '#FFFFFF' },

      'status-critical': '#FF4040',
      'status-warning': '#D39F00',
      'status-ok': '#00A042',

      'header-background': {
        dark: 'brand',
        light: 'brand',
      },

      'header-pattern': {
        dark: '',
        light: '',
      },

      background: {
        dark: '#282828',
        light: '#FBFBFB',
      },
      'background-front': {
        dark: '#343434',
        light: '#EFEFEF',
      },
      'background-back': {
        dark: '#4A4A4A',
        light: '#E0E0E0',
      },
      'background-contrast': {
        light: '#33333310',
        dark: '#FFFFFF18',
      },
      'background-highlight': {
        light: '#00000008',
        dark: '#FFFFFF08',
      },
      border: {
        dark: '#BEBEBE',
        light: '#6E6E6E',
      },

      control: {
        dark: '#ECECEC',
        light: '#4A4A4A',
      },
      'control-text': { dark: '#4A4A4A', light: '#F8F8F8' },
      'control-text-weak': {
        dark: lighten(0.2, '#4A4A4A'),
        light: darken(0.2, '#F8F8F8'),
      },
      secondary: '#adadad',
      'secondary-active': darken(0.2, '#adadad'),
      'secondary-hover': darken(0.1, '#adadad'),
      active: {
        light: '#E0E0E0',
        dark: '#4A4A4A',
      },
      hover: {
        dark: darken(0.1, '#ECECEC'),
        light: darken(0.1, '#4A4A4A'),
      },

      text: { light: '#343434', dark: '#EFEFEF' },
      'text-weak': { light: '#929292', dark: '#929292' },
      'text-strong': {
        dark: lighten(0.3, '#F8F8F8'),
        light: darken(0.3, '#444444'),
      },
      heading: {
        dark: '#F8F8F8',
        light: '#4A4A4A',
      },

      focus: {
        dark: 'rgba(239, 239, 239, 0.5)',
        light: 'rgb(52, 52, 52, 0.5)',
      },

      placeholder: { light: '#6E6E6E', dark: '#BEBEBE' },
    },
    font: {
      links: [],
      family: 'Helvetica, Arial, sans-serif',
      height: '14px',
      weight: 400,
      size: '14px',
    },
    edgeSize: {
      responsiveBreakpoint: 'small',
      xlarge: '64px',
      large: '48px',
      medium: '36px',
      small: '24px', // this stops busy indicator
      xsmall: '16px',
      xxsmall: '8px',
      hair: '1px',
    },
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: '4px',
      large: '12px',
      xlarge: '24px',
    },
    breakpoints: {
      xsmall: {
        value: 650,
      },
      small: {
        value: 950,
        edgeSize: {
          xlarge: '48px',
          large: '36px',
          medium: '24px',
          small: '16px',
          xsmall: '8px',
          xxsmall: '4px',
          hair: '1px',
        },
        borderSize: {
          xsmall: '1px',
          small: '2px',
          medium: '4px',
          large: '6px',
          xlarge: '12px',
        }
      },
      medium: {
        value: 1100,
      },
      large: {
        value: 1750,
      },
      xlarge: {
        value: 2000,
      },
    },
    elevation: {
      light: {
        none: 'none',
        xsmall: '2px 2px 1px rgba(0, 0, 0, 0.20)',
        small: '0px 2px 4px rgba(0, 0, 0, 0.20)',
        medium: '0px 4px 8px rgba(0, 0, 0, 0.20)',
        large: '0px 8px 16px rgba(0, 0, 0, 0.20)',
        xlarge: '0px 12px 24px rgba(0, 0, 0, 0.20)',
        glow: '0 0 14px 0 rgba(0,0,0,0.24)',
        selected: css`0 0 4px 2px ${({ theme }) => {
          const color = theme.global.colors['accent-1'];
          return typeof color === 'object' ? color[theme.dark ? 'dark' : 'light'] : color;
        }}`,
      },
      dark: {
        none: 'none',
        xsmall: '2px 2px 1px rgba(0,0,0,0.25)',
        small: '0px 4px 4px rgba(0,0,0,0.25)',
        medium: '0px 6px 8px rgba(0,0,0,0.25)',
        large: '0px 8px 16px rgba(0,0,0,0.25)',
        xlarge: '0px 12px 24px rgba(0,0,0,0.25)',
        glow: '0 0 14px 0 rgba(0,0,0,0.24)',
        selected: css`0 0 4px 2px ${({ theme }) => {
          const color = theme.global.colors['accent-1'];
          return typeof color === 'object' ? color[theme.dark ? 'dark' : 'light'] : color;
        }}`,
      },
    },
    focus: {
      // shadow or outline are required for accessibility
      border: {
        // remove to only have shadow
        color: undefined,
      },
      shadow: {
        color: 'focus',
        size: '1px',
      },
    },

    control: {
      border: {
        width: '1px',
        radius: '6px',
        color: { light: '#929292', dark: '#929292' },
      },
      disabled: {
        opacity: 0.5,
      },
    },

    input: {
      padding: {
        horizontal: '11px',
        top: '16px',
        bottom: '16px',
      },
      font: {
        weight: 300,
      },
      tip: {
        offset: '5px',
      },
      icon: {
        padding: {
          left: '48px',
          right: '48px',
        },
      },
      error: {
        bottom: '-17px',
      },
    },

    drop: {
      // intelligentMargin: undefined,
      background: 'background',
      border: {
        radius: '4px',
      },
      // margin: undefined
      // shadowSize: 'small', // shadowSize is deprecated, use 'elevation'
      elevation: 'small',
      zIndex: '300',
      extend: ({ alignProp }: TDropThemableProps): FlattenSimpleInterpolation =>
        css`
          transform-origin: ${getTransformOriginStyle(alignProp)};
        `,
    },
  },

  box: {
    responsiveBreakpoint: 'small', // when we switch rows to columns
    extend: (): FlattenSimpleInterpolation => css`
      min-height: auto;
    `,
    border: {
      radius: {
        xsmall: '2px',
        small: '3px',
        medium: '4px',
        large: '6px',
        xlarge: '8px',
        xxlarge: '15px',
        full: '50%',
      },
    },
  },
  heading: {
    weight: 500,
    color: 'heading',
    level: {
      1: {
        xlarge: {
          size: '114px',
          height: '120px',
          maxWidth: '2736px',
        },
        xsmall: {
          size: '24px',
          height: '32px',
        },
      },
      '2': {
        xlarge: {
          size: '64px',
        },
        large: {
          size: '46px',
          height: '60px',
        },
        medium: {
          size: '36px',
          height: '53px',
        },
        small: {
          size: '24px',
          maxWidth: '650px',
        },
        xsmall: {
          size: '18px',
          height: '26px',
        },
      },
      '3': {
        large: {
          size: '36px',
          height: '47px',
        },
        medium: {
          size: '24px',
        },
      },
    },
  },
  text: {
    extend: ({ weight }: TextExtendedProps): FlattenSimpleInterpolation => css`
      font-weight: ${weight ?? 300};
    `,
    '6xl': {
      size: '144px',
      height: '164px',
    },
    '5xl': {
      size: '120px',
      height: '150px',
    },
    '4xl': {
      size: '96px',
      height: '116px',
    },
    '3xl': {
      size: '72px',
      height: '82px',
    },
    '2xl': {
      size: '48px',
      height: '58px',
    },
    xxlarge: {
      size: '36px',
      height: '46px',
    },
    xlarge: {
      size: '30px',
      mobileSize: '18px',
      height: '40px',
    },
    large: {
      size: '24px',
      mobileSize: '15px',
      height: '30px',
    },
    medium: {
      size: '18px',
      mobileSize: '15px',
      height: '24px',
    },
    small: {
      size: '16px',
      mobileSize: '12px',
      height: '22px',
    },
    xsmall: {
      size: '14px',
      mobileSize: '12px',
      height: '16px',
    },
  },
  paragraph: {
    extend: ({ weight }: TextExtendedProps): FlattenSimpleInterpolation => css`
      font-weight: ${weight ?? 300};
      white-space: pre-wrap;
    `,

    xxlarge: {
      size: '36px',
      height: '46px',
      maxWidth: undefined,
    },

    xlarge: { size: '30px', height: '40px', maxWidth: undefined },
    large: { size: '24px', height: '32px', maxWidth: undefined },
    medium: { size: '18px', height: '26px', maxWidth: undefined },
    small: { size: '16px', height: '22px', maxWidth: undefined },
    xsmall: { size: '14px', height: '18px', maxWidth: undefined },
  },

  anchor: {
    textDecoration: 'none',
    fontWeight: 300,
    color: 'text',
    hover: {
      textDecoration: 'underline',
    },
  },
  button: {
    border: {
      radius: '4px',
    },
    gap: '12px',
    size: {
      small: {
        border: {
          radius: '3px',
        },
        pad: {
          horizontal: '11px',
          vertical: '7px',
        },
      },
      medium: {
        border: {
          radius: '4px',
        },
        pad: {
          horizontal: '14px',
          vertical: '12px',
        },
      },
      large: {
        border: {
          radius: '5px',
        },
        pad: {
          horizontal: '16px',
          vertical: '17px',
        },
      },
    },
    default: {
      color: 'white'
    },
    primary: {
      color: 'white',
      background: {
        color: 'accent-1'
      },
      font: {
        weight: 500
      }
    },
    secondary: {
      color: 'white',
      background: {
        color: 'grey-6'
      }
    },
    hover: {
      // TODO: Can we make this fade out too?
      extend: (): FlattenSimpleInterpolation => css`
        transition: 0.5s ease;
        filter: brightness(0.85);
      `,
    }
  },

  icon: {
    size: {
      large: '28px',
      medium: '22px',
      small: '20px',
    },
  },
  list: {
    item: {
      border: undefined,
      pad: {
        horizontal: 'small',
        vertical: 'xsmall',
      },
    },
  },

  avatar: {
    size: {
      xsmall: '18px',
      small: '24px',
      medium: '48px',
      large: '72px',
      xlarge: '96px',
    },
  },
  carousel: {
    icons: {
      color: 'light-5',
    },
  },

  checkBox: {
    border: {
      color: 'grey-6',
      width: '1px',
    },
    color: '#fbfbfb', // checked icon color (inc. toggle)
    hover: undefined,
    check: {
      thickness: '2px',
      extend: ({
        checked,
        indeterminate,
        theme,
      }: CheckboxThemableTProps): FlattenSimpleInterpolation => css`
        background: ${checked || indeterminate ? getGlobalColor(theme, 'grey-6') : 'none'};
        border-color: ${getGlobalColor(theme, 'grey-6')};
      `,
    },
    icon: {
      size: '22px',
      extend: (): FlattenSimpleInterpolation => css`
        margin: -2px;
      `,
    },
    size: '20px',
    gap: 'xsmall',
    extend: ({ toggle, theme }: CheckboxThemableTProps): FlattenSimpleInterpolation | undefined => {
      const totalWidth = theme?.checkBox?.toggle?.size ?? '';
      const knobWidth = theme?.checkBox?.size ?? '';

      return toggle
        ? css`
            & input + span > span {
              top: 2px;
              left: 2px;
            }

            & input:checked + span > span {
              top: 2px;
              left: calc(${totalWidth} - ${knobWidth} - 2px);
            }
          `
        : undefined;
    },
    toggle: {
      background: '#bebebe',
      color: 'white',
      extend: ({
        toggle,
        checked,
      }: CheckboxThemableTProps): FlattenSimpleInterpolation | undefined =>
        toggle
          ? css`
              height: 24px;
              border: none;
              background: ${checked && '#6E6E6E'};
            `
          : undefined,
    },
  },
  radioButton: {
    border: {
      color: 'grey-6',
      width: '1px',
    },
    color: 'grey-6', // checked border color
    check: {
      color: 'grey-6',
    },
    hover: {
      border: {
        color: 'grey-6',
      },
    },
    icon: {
      size: '16px',
    },
    size: '20px'
  },
  accordion: {
    panel: {
      border: {
        color: 'transparent',
      },
    },
    border: {
      color: 'transparent',
    },
    icons: {
      collapse: undefined,
      expand: undefined,
    },
  },

  layer: {
    background: 'grey-1',
    border: {
      radius: '4px',
      // intelligentRounding: undefined,
    },
    container: {
      elevation: 'glow',
      zIndex: '20',
      extend: (): FlattenSimpleInterpolation =>
        css`
          overflow: hidden;
        `,
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.75)',
    },
    responsiveBreakpoint: 'small', // when Layer takes over the full screen
    zIndex: '300',
  },
  calendar: {
    day: {
      extend: (): FlattenSimpleInterpolation => css`
        color: 'blue';
      `,
    },
    // daySize must align with global.size
    small: {
      // fontSize: `${baseFontSize - fontScale}px`,
      lineHeight: 1.375,
      // daySize: `${(baseSpacing * 8) / 7}px`,
      slideDuration: '0.2s',
    },
    medium: {
      // fontSize: `${baseFontSize}px`,
      lineHeight: 1.45,
      // daySize: `${(baseSpacing * 16) / 7}px`,
      slideDuration: '0.5s',
    },
    large: {
      // fontSize: `${baseFontSize + 3 * fontScale}px`,
      lineHeight: 1.11,
      // daySize: `${(baseSpacing * 32) / 7}px`,
      slideDuration: '0.8s',
    },
    heading: { level: '4' }, // level ranges from 1-6
  },
  formField: {
    label: {
      size: 'small',
      margin: { bottom: 'xsmall', horizontal: '0' },
    },
    border: false,
  },
  page: {
    wide: {
      width: {
        min: 'small',
        max: 'xlarge',
      },
      xlarge: {
        width: '1280px',
      },
    },
  },
  toggleGroup: {
    button: {
      pad: {
        horizontal: 'large',
        vertical: '6px',
      }
    },
    container: {
      round: '3px',
      border: false,
      background: 'grey-11'
    },
    divider: {
      color: 'FFFFFF00'
    }
  }
};
