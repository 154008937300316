import React, { forwardRef, RefObject } from 'react';
import styled from 'styled-components';

import { useBoxBorderRadius, ShortTshirtSizesType, useEdgeSize } from '@lwe/toolkit/theming';

import { Box as BaseBox, BoxExtendedProps } from 'grommet';

const CustomBox = styled(BaseBox)<BoxAditionalProps>`
  ${({ position }) => position !== undefined && 'position: ' + position + ';'}
  ${({ zIndex }) => zIndex !== undefined && 'z-index: ' + zIndex + ';'}
  ${({ top }) => top !== undefined && 'top: ' + top + ';'}
  ${({ left }) => left !== undefined && 'left: ' + left + ';'}
  ${({ bottom }) => bottom !== undefined && 'bottom: ' + bottom + ';'}
  ${({ right }) => right !== undefined && 'right: ' + right + ';'}
  ${({ offset }) =>
    offset !== undefined && 'transform: translateX(' + offset.x + ') translateY(' + offset.y + ');'}

  ${({ minHeight }) => minHeight && `min-height:${minHeight};`}
`;

type BoxAditionalProps = {
  position?: 'relative' | 'absolute' | 'fixed';
  zIndex?: number;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  offset?: { x: string; y: string };
  minHeight?: string;
};

type TProps = BoxAditionalProps &
  Omit<BoxExtendedProps, 'css' | 'round'> & {
    round?:
      | ShortTshirtSizesType
      | 'full'
      | string
      | {
          corner?:
            | 'top'
            | 'left'
            | 'bottom'
            | 'right'
            | 'top-left'
            | 'top-right'
            | 'bottom-left'
            | 'bottom-right'
            | undefined;
          size?: ShortTshirtSizesType | 'full' | string;
        };
  };

const Box = forwardRef(
  (
    { round, height, ...rest }: TProps,
    ref: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null,
  ): JSX.Element => {
    const minHeight = typeof height !== 'string' ? height?.min : undefined;

    let borderRadius;
    let borderRound;

    if (typeof round === 'string' || round === undefined) {
      borderRadius = useBoxBorderRadius(round);
      borderRound = borderRadius;
    } else {
      borderRadius = useBoxBorderRadius(round?.size);
      borderRound = { size: borderRadius, corner: round?.corner };
    }

    const { str: minHeightPx } = useEdgeSize(minHeight ?? '');

    return (
      <CustomBox
        ref={ref}
        round={borderRound}
        height={height}
        minHeight={minHeight ? minHeightPx : undefined}
        {...rest}
      />
    );
  },
);

export default Box;
