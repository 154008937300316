import React, { ReactNode } from 'react';
import { PusherProvider as ReactPusherProvider } from '@petarslavnic/react-pusher';
import Pusher from 'pusher-js';

type PusherProviderPropsType = {
  pusher: Pusher;
  children?: ReactNode;
};

const PusherProvider = ({ pusher, ...rest }: PusherProviderPropsType): JSX.Element => {
  return <ReactPusherProvider instance={pusher} {...rest} />;
};

export default PusherProvider;
