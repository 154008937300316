import { useContext, useRef, useMemo, useEffect, useState, useCallback } from 'react';
import { ResponsiveContext } from 'grommet';

export const useIsMobile = (): boolean => {
  const size = useContext<string>(ResponsiveContext);

  return useMemo(() => size === 'small' || size === 'xsmall', [size]);
};

export const useIsPhone = (): boolean => {
  const size = useContext<string>(ResponsiveContext);

  return useMemo(() => size === 'xsmall', [size]);
};

export const useIsTablet = (): boolean => {
  const size = useContext<string>(ResponsiveContext);

  return useMemo(() => size === 'small', [size]);
};

export const useIsDesktop = (): boolean => {
  const size = useContext<string>(ResponsiveContext);

  return useMemo(() => size === 'large' || size === 'medium', [size]);
};

export const useIsNarrowDesktop = (): boolean => {
  const size = useContext<string>(ResponsiveContext);

  return useMemo(() => size === 'medium', [size]);
};

export const useIsLargeDesktop = (): boolean => {
  const size = useContext<string>(ResponsiveContext);

  return useMemo(() => size === 'large' || size === 'xlarge', [size]);
};

export const useIsHD = (): boolean => {
  const size = useContext<string>(ResponsiveContext);

  return useMemo(() => size === 'xlarge', [size]);
};

const storedDarkModeOption =
  typeof window !== 'undefined' ? localStorage.getItem('darkMode') ?? 'system' : 'system';
const systemIsDark =
  typeof window !== 'undefined' ? window.matchMedia('(prefers-color-scheme: dark)').matches : false;

const darkModeOptionIsDark = (option: string, systemDarkMode: boolean) => {
  switch (option) {
    case 'dark':
      return true;
    case 'light':
      return false;
  }

  return systemDarkMode;
};

const useForceRender = () => {
  const [, render] = useState<number>(0);
  return useCallback(() => render((i) => i + 1), [render]);
};

const addMediaChangeEventListener = (
  matchMedia: MediaQueryList,
  handler: (event: MediaQueryListEvent) => void,
) => {
  if (matchMedia?.addEventListener) {
    return matchMedia.addEventListener('change', handler);
  }
  // tslint:disable-next-line
  return matchMedia.addListener(handler);
};

const removeMediaChangeEventListener = (
  matchMedia: MediaQueryList,
  handler: (event: MediaQueryListEvent) => void,
) => {
  if (matchMedia?.removeEventListener) {
    return matchMedia.removeEventListener('change', handler);
  }
  // tslint:disable-next-line
  return matchMedia.removeListener(handler);
};

export const useDarkMode = (): any => {
  const forceRender = useForceRender();
  const [darkModeOption, setDarkModeOption] = useState(storedDarkModeOption);
  const systemDarkModeRef = useRef<boolean>(systemIsDark);
  const darkMode = darkModeOptionIsDark(darkModeOption, systemDarkModeRef.current);

  useEffect(() => localStorage.setItem('darkMode', darkModeOption), [darkModeOption]);

  useEffect(() => {
    const handler = (event: MediaQueryListEvent) => {
      const previous = systemDarkModeRef.current;
      systemDarkModeRef.current = event.matches;
      if (previous !== systemDarkModeRef.current) forceRender();
    };
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
    addMediaChangeEventListener(matchMedia, handler);
    return () => removeMediaChangeEventListener(matchMedia, handler);
  }, [darkModeOption, systemDarkModeRef]);

  return [darkMode, darkModeOption, setDarkModeOption];
};
