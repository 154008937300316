import { ApolloCache, FieldMergeFunction, FieldReadFunction } from '@apollo/client';

type RefObjectType = {
  __ref: string;
};

export const resolveUserNames = (student = null): FieldReadFunction => {
  return (existing, { toReference, readField }) => {
    const seats = readField('seats', existing)?.nodes;

    if (!seats) return existing;

    const moderators = readField('moderators', existing) ?? [];
    const students = seats.map((seat) => readField('student', seat));
    const exists = !!students.find((s) => readField('id', s) === readField('id', student));
    const users = exists ? [...students, ...moderators] : [...students, ...moderators, student];

    const names =
      users?.map((user: RefObjectType) => {
        const profile = readField<RefObjectType>('profile', user);
        const firstName = readField<string>('firstName', profile);
        return firstName;
      }) ?? [];

    users?.forEach((user: RefObjectType) => {
      const profile = readField<RefObjectType>('profile', user);
      const firstName = readField<string>('firstName', profile);

      toReference(
        {
          __typename: 'User',
          id: readField<string>('id', user),
          hasNameClash: names.filter((n) => n === firstName).length > 1,
        },
        true,
      );
    });

    return existing;
  };
};

const defaultItemMapper = (item, toReference) => toReference(item);

export const addSnackbarNotification = (action, cache, itemMapper = defaultItemMapper) => {
  cache.modify({
    fields: {
      notifications(existing, { toReference }) {
        const item = itemMapper(action, toReference);
        return [...existing, item];
      },
    },
  });
};

export const updateUserNames = (cache: ApolloCache<object>, seat, user) => {
  cache.modify({
    id: cache.identify({ ...seat }),
    fields: {
      classroom: resolveUserNames(user),
    },
  });
};
