import React from 'react';
import { PageContent, Box, Text, Anchor } from 'grommet';

const Footer = () => {
  return (
    <PageContent as="footer" background="grey-8" align="center">
      <Box
        width={{ width: '100%', max: 'xlarge' }}
        direction="row-responsive"
        pad="small"
        gap="small"
        justify="center"
      >
        <Text size="small">© 2023 Learning with Experts</Text>
        <Anchor
          href="https://www.learningwithexperts.com/terms-and-conditions"
          target="_blank"
          size="small"
        >
          Terms & conditions
        </Anchor>
        <Anchor
          href="https://www.learningwithexperts.com/privacy-policy"
          target="_blank"
          size="small"
        >
          Privacy policy
        </Anchor>
      </Box>
    </PageContent>
  );
};

export default Footer;
