import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useGlobalColor, useAvatarSize, ShortTshirtSizesType } from '@lwe/toolkit/theming';

import LazyImage, { ExtendedLazyImageProps } from '@ui-root/LazyImage';
import Box from '@ui-root/Box';

const Image = styled(LazyImage)`
  object-fit: cover;
  border-radius: 50%;
`;

export type ExtendedAvatarImageProps = ExtendedLazyImageProps & {
  size?: ShortTshirtSizesType;
  onLoad?: () => void;
};

type TProps = ExtendedAvatarImageProps & {
  src?: string;
  srcSet?: string;
  fallback?: boolean;
};

const AvatarImage = ({
  size = 'large',
  src,
  srcSet,
  onLoad,
  fallback = true,
  fade = true,
  ...rest
}: TProps): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const bg = useGlobalColor('grey-5');
  const {
    image: { int: intSize, str: strSize },
  } = useAvatarSize(size);
  const iconWidth = intSize * 0.833;

  const handleLoad = useCallback(() => {
    setLoaded(true);

    if (onLoad) onLoad();
  }, [setLoaded, onLoad]);

  const srcProvided = src || srcSet;
  const showDefault = fallback && ((srcProvided && !loaded) || !srcProvided);

  return (
    <Box position="relative">
      {srcProvided && (
        <Image
          fade={fade}
          width={strSize}
          height={strSize}
          src={src}
          srcSet={srcSet}
          onLoad={handleLoad}
          {...rest}
        />
      )}

      {showDefault && (
        <Box
          round="full"
          overflow="hidden"
          width={strSize}
          height={strSize}
          justify="center"
          align="center"
          position={srcProvided ? 'absolute' : 'relative'}
        >
          <svg
            width={`${iconWidth}px`}
            height={`${iconWidth * (62 / 70)}px`}
            viewBox="0 0 70 62"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Default Avatar</title>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Icon-/-navigation-/-profile" fill={bg}>
                <path
                  d="M16.6602316,38.67968 C11.8711316,41.86718 8.99223155,47.24608 9.00001575,52.99968 L9.00001575,58.99968 L61.0000474,58.99968 L61.0000474,52.99968 C61.0078436,47.24578 58.1289316,41.86668 53.3398316,38.67968 L52.9999916,38.4414 C50.5624916,36.8203 47.9218916,35.5391 45.1405916,34.6289 C47.6171916,32.0351 48.9999916,28.5859 48.9999916,25 L48.9999916,17 C48.9999916,9.2695 42.7304916,3 34.9999916,3 C27.2694916,3 20.9999916,9.2695 20.9999916,17 L20.9999916,25 C20.9999916,28.5938 22.3866916,32.0469 24.8710916,34.6406 C22.0858916,35.54685 19.4374916,36.832 16.9999916,38.4492 L16.6602316,38.67968 Z M27.0002316,24.99968 L27.0002316,16.99968 C27.0002316,12.58168 30.5822316,8.99968 35.0002316,8.99968 C39.4182316,8.99968 43.0002316,12.58168 43.0002316,16.99968 L43.0002316,24.99968 C43.0002316,29.41768 39.4182316,32.99968 35.0002316,32.99968 C30.5822316,32.99968 27.0002316,29.41768 27.0002316,24.99968 Z M20.0002316,43.67168 L20.3517916,43.44121 C24.6915916,40.54671 29.7892916,39.00371 35.0037916,39.00371 C40.2225916,39.00371 45.3197916,40.54671 49.6597916,43.44121 L50.0113516,43.67168 C53.1285516,45.74978 54.9996516,49.24978 54.9996516,52.99978 L14.9996516,52.99978 C14.9996516,49.24978 16.8785516,45.74588 19.9996516,43.67168 L20.0002316,43.67168 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </svg>
        </Box>
      )}
    </Box>
  );
};

export default AvatarImage;
