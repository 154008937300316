import { useCallback, useEffect, useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import { PusherProvider } from '@petarslavnic/react-pusher';
import env from '@lwe/toolkit/env';
import { monitorAuthToken } from '@lwe/toolkit/authentication';
import initClient from '../client/initClient';
import Maintenance from './components/Maintenance';

const PUSHER_KEY = env('PUSHER_KEY');
const PUSHER_CLUSTER = env('PUSHER_CLUSTER');
const API_ENDPOINT = env('API_ENDPOINT');

const APPSIGNAL_FRONTEND_KEY = env('APPSIGNAL_FRONTEND_KEY');

const appsignal = APPSIGNAL_FRONTEND_KEY
  ? new Appsignal({
      key: APPSIGNAL_FRONTEND_KEY,
      revision: env('APP_VERSION'),
    })
  : null;

interface Props {
  children: React.ReactNode | React.ReactNode[] | null;
}

const fetchWithErrorHandler = (onError?: (e: Error) => void) => (url, data) => {
  return new Promise<Response>((resolve, reject) => {
    fetch(url, data)
      .then((response) => {
        // response only can be ok in range of 2XX
        if (response.ok) {
          // you can call response.json() here too if you want to return json
          resolve(response);
        } else {
          switch (response.status) {
            case 404:
              console.log('Object not found');
              break;
            case 500:
              console.log('Internal server error');
              break;
            default:
              console.log('Some error occured');
              break;
          }

          onError?.(new Error('Error'));
          reject(response);
        }
      })
      .catch((error) => {
        //it will be invoked mostly for network errors
        console.log(error);
        onError?.(error);
        reject(error);
      });
  });
};

const Provider = ({ children }: Props) => {
  const { showBoundary } = useErrorBoundary();

  const { client, pusher } = useMemo(() => {
    return initClient({
      pusherKey: PUSHER_KEY,
      pusherCluster: PUSHER_CLUSTER,
      apiEndpoint: API_ENDPOINT ?? '',
      fetch: fetchWithErrorHandler(showBoundary),
    });
  }, [showBoundary]);

  useEffect(() => {
    if (client) {
      monitorAuthToken(client);
    }
  }, [client]);

  if (!pusher) return null;

  return (
    <PusherProvider instance={pusher}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </PusherProvider>
  );
};

const ApolloAndPusherProviderWithErrorHandler = ({ children }: Props) => {
  const handleError = useCallback((error) => {
    if (appsignal) {
      const span = appsignal.createSpan();
      span.setError(error).setTags({ framework: 'React' });
      appsignal.send(span);
    } else {
      console.error(error);
    }
  }, []);

  return (
    <ErrorBoundary onError={handleError} FallbackComponent={Maintenance}>
      <Provider>{children}</Provider>
    </ErrorBoundary>
  );
};

export default ApolloAndPusherProviderWithErrorHandler;
