import React, {
  createContext,
  useMemo,
  useCallback,
  useContext,
  useEffect,
  ReactNode,
  DependencyList,
} from 'react';
import env from '@lwe/toolkit/env';

type TContext = {
  subscribe: (type: string, listener: EventListenerOrEventListenerObject | null) => void;
  unsubscribe: (type: string, listener: EventListenerOrEventListenerObject | null) => void;
  publish: (type: string, payload?: unknown | null) => void;
};

const EventBusContext = createContext<TContext>({
  subscribe: (type: string, listener: EventListenerOrEventListenerObject | null) => {
    console.error(`eventbus subscribe for ${type} called outside of provider`, listener);
  },
  unsubscribe: (type: string, listener: EventListenerOrEventListenerObject | null) => {
    console.error(`eventbus unsubscribe for ${type} called outside of provider`, listener);
  },
  publish: (type: string, detail?: unknown | null) => {
    console.error(`eventbus publish for ${type} called outside of provider`, detail);
  },
});

const development = 'production' !== env('ENVIRONMENT');

class EventBus {
  eventTarget?: Comment;

  constructor(description = 's') {
    if (typeof document === 'undefined') return;

    this.eventTarget = document.appendChild(document.createComment(description));
  }

  subscribe(type: string, listener: EventListenerOrEventListenerObject | null): void | undefined {
    this.eventTarget?.addEventListener(type, listener);
  }

  unsubscribe(type: string, listener: EventListenerOrEventListenerObject | null): void | undefined {
    this.eventTarget?.removeEventListener(type, listener);
  }

  publish(type: string, detail?: unknown | null) {
    this.eventTarget?.dispatchEvent(new CustomEvent(type, { detail }));
  }
}

export const EventBusProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const value = useMemo(() => {
    const bus = new EventBus();
    return {
      subscribe: (...args: [type: string, listener: EventListenerOrEventListenerObject | null]) => {
        bus.subscribe(...args);
      },
      unsubscribe: (...args: [type: string, listener: EventListenerOrEventListenerObject | null]) =>
        bus.unsubscribe(...args),
      publish: (type: string, detail?: unknown | null) => {
        if (development) {
          console.log(`[eventbus] (${type}) published`, detail);
        }
        bus.publish(type, detail);
      },
    };
  }, []);

  return <EventBusContext.Provider value={value}>{children}</EventBusContext.Provider>;
};

export const useEventBus = (): TContext => {
  return useContext(EventBusContext);
};

export const useEventBusSubscription = (
  event: string,
  handler: (e: Event) => void,
  deps: DependencyList,
): void => {
  const bus = useEventBus();

  const listener = useCallback((e) => {
    if (development) {
      console.log(`[eventbus] (${event}) listener`, e);
    }
    handler(e);
  }, deps ?? []);

  return useEffect(() => {
    bus?.subscribe(event, listener);
    return () => bus?.unsubscribe(event, listener);
  }, [listener]);
};
