import React, { ReactNode, createContext } from 'react';
import { Grommet, GrommetExtendedProps, ThemeType } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { gql } from '@apollo/client';
import { useDarkMode } from '../hooks';

import defaultTheme from '../theme';
import GlobalStyle from './styles';

type ThemeModeContextType = {
  setDarkModeOption: (id: string) => void;
  darkModeOption: string;
};

export const ThemeModeContext = createContext<ThemeModeContextType>({
  setDarkModeOption: (id: string) => undefined,
  darkModeOption: 'system',
});

interface TProps extends GrommetExtendedProps {
  children?: ReactNode;
  theme?: ThemeType;
  full?: boolean;
  themeMode?: 'dark' | 'light';
}

const PartnerThemeProvider = ({
  children,
  theme,
  themeMode,
  full = true,
  ...rest
}: TProps): JSX.Element => {
  const [isDark, darkModeOption, setDarkModeOption] = useDarkMode();
  return (
    <ThemeModeContext.Provider value={{ darkModeOption, setDarkModeOption }}>
      <Grommet
        full={full}
        plain
        cssVars
        theme={deepMerge(defaultTheme, theme ?? {})}
        themeMode={themeMode ?? (isDark ? 'dark' : 'light')}
        id="scroll-body"
        {...rest}
      >
        <GlobalStyle />
        {children}
      </Grommet>
    </ThemeModeContext.Provider>
  );
};

export default PartnerThemeProvider;

PartnerThemeProvider.fragments = {
  partner: gql`
    fragment Configuration__partner on Configuration {
      id
      theme
    }
  `,
};
