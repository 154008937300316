import { gql } from '@apollo/client';

const typeDefs = gql`
  extend type File {
    progress: Float
  }

  extend type Lesson {
    isAvailable: Boolean!
  }

  extend type User {
    online: Boolean!
    displayName: String!
    fullName: String!
    isClassroomTutor: Boolean!
    isModerator: Boolean!
    isStudent: Boolean!
    isViewer: Boolean!
    badgeLabel: String
  }

  type Draft {
    id: ID!
    body: String
    attachments: [Attachment!]!
  }

  extend type Video {
    progress: Float
  }

  extend type Query {
    isMobile: Boolean!
    basketToken: String
    currencyCode: String!
    partner: String!
    affiliate: String
    draft(id: ID!): Draft
  }

  interface Typing {
    typing: [User]!
  }

  extend type Classroom implements Typing {
    typing: [User]!
  }

  extend type Assignment implements Typing {
    typing: [User]!
  }

  extend type ClassroomMessage implements Typing {
    typing: [User]!
  }

  extend type AssignmentMessage implements Typing {
    typing: [User]!
  }

  extend type PrivateConversation implements Typing {
    typing: [User]!
  }

  extend type ProjectSubmission implements Typing {
    typing: [User]!
  }

  extend type Project implements Typing {
    typing: [User]!
  }

  extend type ClassmateJoinedFI {
    new: Boolean
  }

  extend type ClassroomMessageReplyFI {
    new: Boolean
  }

  extend type ClassroomMessageFI {
    new: Boolean
  }

  extend type ProjectSubmissionMarkedFI {
    new: Boolean
  }

  extend type ProjectSubmissionMessageFI {
    new: Boolean
  }

  extend type ProjectSubmissionSubmittedFI {
    new: Boolean
  }
`;

export default typeDefs;
