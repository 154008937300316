let config: Record<string, string | undefined>;

try {
  config = JSON.parse(document.getElementById('js-config')?.textContent ?? '{}')
} catch (e) {
  // console.error('Problem configuring app', e);
  config = process?.env ?? {};
}

const env = (key: string) => config[key];

export default env;
