import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { initClient } from '@lwe/apollo';
import { ApolloProvider } from '@apollo/client';
import Layout from './src/layouts';
import env from '@lwe/toolkit/env';
import { PartnerThemeProvider } from '@lwe/toolkit/theming';
import { EventBusProvider } from '@lwe/toolkit/utils';
import { ResponsiveContext } from 'grommet';

(window as any).global = window;

const { client } = initClient({
  apiEndpoint: env('API_ENDPOINT') ?? '',
});

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <EventBusProvider>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </EventBusProvider>
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return <Layout>{element}</Layout>;
};
