import {
  gql,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  DefaultOptions,
  NormalizedCacheObject,
  ServerError,
} from '@apollo/client';
import { onError as onErrorLink } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import PusherLink from 'graphql-ruby-client/subscriptions/PusherLink';
import Pusher from 'pusher-js';
import env from '@lwe/toolkit/env';
import TimeShift from 'timeshift-js';
import typePolicies from '../graphql/typePolicies';
import fragmentTypes from '../fragmentTypes.json';
import typeDefs from '../graphql/types';
import { Authorizer, AuthorizerCallback, Channel } from 'pusher-js';

type InitialState = {
  isMobile: boolean;
  basketToken: string | null;
  affiliate: string | null;
};

const INITIAL_DATA = gql`
  query InitialData {
    isMobile @client
    basketToken @client
    affiliate @client
  }
`;

const authorizer =
  (apiEndpoint: string) =>
  (channel: Channel): Authorizer => ({
    async authorize(socketId: string, callback: AuthorizerCallback) {
      try {
        const body = new FormData();
        body.append('channel_name', channel?.name);
        body.append('socket_id', socketId);
        const response = await fetch(`${apiEndpoint}/pusher/auth`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
          body,
        });
        const data = await response.json();

        callback(null, data);
      } catch (err) {
        callback(new Error(`Error calling auth endpoint: ${err}`), { auth: '' });
      }
    },
  });

type InitClientPropsType = {
  pusherKey?: string;
  pusherCluster?: string;
  apiEndpoint: string;
  fetch?: (input: RequestInfo | URL, init?: RequestInit | undefined) => Promise<Response>;
  onError?: () => void;
};

type InitClientreturnType = {
  client: ApolloClient<NormalizedCacheObject>;
  pusher?: Pusher;
};

const nonProduction = env('ENV') !== 'production';

const initClient = ({
  pusherKey,
  pusherCluster,
  apiEndpoint,
  fetch,
  onError,
}: InitClientPropsType): InitClientreturnType => {
  const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

  const basketToken = localStorage.getItem('basketToken');
  const params = new URLSearchParams(location.search);

  const aud = params.get('aud');
  const aff = params.get('aff');
  const now = params.get('now');

  if (aud) {
    sessionStorage.setItem('audience', aud);
  }

  if (aff) {
    sessionStorage.setItem('affiliate', aff);
  }

  if (now) {
    sessionStorage.setItem('now', now);
  }

  if (nonProduction && sessionStorage.getItem('now')) {
    window.Date = TimeShift.Date;
    const originalDate = new TimeShift.OriginalDate().getTime();
    const now = Date.parse(sessionStorage.getItem('now') ?? '');

    TimeShift.setTime(() => {
      const dateNow = new TimeShift.OriginalDate().getTime();
      return now + dateNow - originalDate;
    });
  }

  const initialState: InitialState = {
    isMobile: vw <= 950,
    basketToken,
    affiliate: aff,
  };

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'none',
    },
  };

  const pusher = pusherKey
    ? new Pusher(pusherKey, {
        cluster: pusherCluster,
        authorizer: authorizer(apiEndpoint),
      })
    : undefined;

  if (pusher && process.env.NODE_ENV !== 'production') {
    Pusher.logToConsole = true;
  }

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('authToken');
    const country = env('COUNTRY');
    const audience = sessionStorage.getItem('audience');
    const now = sessionStorage.getItem('now');

    const newHeaders = {
      ...headers,
      'X-Country': country,
      ...(audience && { 'X-Audience': audience }),
      ...(now && { 'X-Time-Travel': now }),
    };

    if (!token) {
      return {
        headers: newHeaders,
      };
    }

    return {
      headers: {
        ...newHeaders,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const cache = new InMemoryCache({
    possibleTypes: fragmentTypes.possibleTypes,
    typePolicies,
  }).restore((window as any)?.__APOLLO_STATE__);

  const errorLink = onErrorLink(({ networkError }) => {
    const error = networkError as ServerError;
    if (error?.statusCode === 503) {
      onError?.();
    }
  });

  cache.writeQuery({
    query: INITIAL_DATA,
    data: initialState,
  });

  const httpLink = new HttpLink({
    uri: `${apiEndpoint}/graphql`,
    fetch,
  });

  const link = ApolloLink.from(
    pusher ? [errorLink, new PusherLink({ pusher }), httpLink] : [errorLink, httpLink],
  );

  const client = new ApolloClient({
    defaultOptions,
    link: ApolloLink.from([authLink, link]),
    cache,
    typeDefs,
    connectToDevTools: process.env.NODE_ENV !== 'production',
  });

  client.onResetStore(async () => {
    cache.restore((window as any)?.__APOLLO_STATE__);
    await cache.writeQuery({
      query: INITIAL_DATA,
      data: initialState,
    });
  });

  return {
    client,
    pusher,
  };
};

export default initClient;
