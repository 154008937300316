import React, { ReactNode, useContext, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Anchor, Box, Header as BaseHeader, BoxExtendedProps, ThemeContext } from 'grommet';
import { BackgroundType } from 'grommet/utils';

import { ExtendedThemeType, useIsMobile } from '@lwe/toolkit/theming';
import { genAssetImgSrc } from '@lwe/toolkit/utils';

import { LazyImage } from '@lwe/toolkit/ui-library';

const Image = styled(LazyImage)`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

type HeaderProps = Omit<BoxExtendedProps, 'background'> & {
  scrolled?: boolean;
  scrollEase?: string;
  desktopHeight?: number;
  mobileHeight?: number;
  background?: BackgroundType;
  homepageRedirect?: string;
  children?: React.ReactNode;
  fadeIn?: boolean;
  sticky?: boolean;
  logo?: ReactNode;
};

const Header = ({
  desktopHeight = 80,
  mobileHeight = 60,
  background = 'header-background',
  homepageRedirect = '/',
  children,
  logo,
  fadeIn = true,
  sticky = false,
  width = '100%',
}: HeaderProps) => {
  const isMobile = useIsMobile();
  const theme = useContext<ExtendedThemeType>(ThemeContext);

  const logoWidth = isMobile ? 210 : 250;
  const logoHeight = isMobile ? 32 : 48;
  const logoUuid = theme?.global?.logo?.web;

  logo = logo ?? (
    <Box
      margin={{ left: isMobile ? '15px' : '22px' }}
      width={{ max: `${logoWidth}px` }}
      fill="vertical"
      justify="center"
      align="start"
    >
      <Anchor href={homepageRedirect ? homepageRedirect : '/'}>
        <Image height={`${logoHeight}px`} {...genAssetImgSrc(logoUuid, 400)} fade={fadeIn} />
      </Anchor>
    </Box>
  );

  return (
    <BaseHeader
      fill="horizontal"
      sticky={sticky ? 'scrollup' : undefined}
      background={background}
      height={isMobile ? `${mobileHeight}px` : `${desktopHeight}px`}
      justify="center"
    >
      <Box width={width} direction="row" justify="between">
        <Box direction="row" align="center" fill="vertical" pad={{ vertical: '16px' }}>
          {logo}
        </Box>
        <Box flex>{children}</Box>
      </Box>
    </BaseHeader>
  );
};

export default Header;
