import { TypePolicies } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { resolveUserNames } from '@lwe/apollo';

type RefObjectType = {
  __ref: string;
};

const TypingAware = {
  fields: {
    typing: {
      merge: false,
      read(typing = []) {
        return typing;
      },
    },
  },
};

const typePolicies: TypePolicies = {
  SlateDocument: {
    fields: {
      document: {
        read(existing) {
          // temp until SlateDocument consistently returns array instead of String sometimes

          try {
            return Array.isArray(existing) ? existing : JSON.parse(existing);
          } catch (e) {
            return null;
          }
        },
      },
    },
  },
  Query: {
    fields: {
      node: {
        read(existing, { toReference, args }) {
          if (existing) return existing;
          // decode the global id and split the type out to redirect

          const [__typename] = window.atob(args?.id).split('/');
          return toReference({
            __typename,
            id: args?.id,
          });
        },
      },
      conversations: relayStylePagination(['unread']),
      submissions: relayStylePagination(['unmarked']),
      user: {
        merge: true,
      },
      courses: {
        merge(existing = [], incoming = [], { args }) {
          if (args && args.before) return [...existing, ...incoming];
          const refs = existing.map((item: RefObjectType) => item.__ref);
          const insertion = incoming.filter((item: RefObjectType) => !refs.includes(item.__ref));
          return [...insertion, ...existing];
        },
      },
      promotions: {
        merge: true,
      },
      seat: {
        merge(existing, incoming, { readField, toReference, mergeObjects }) {
          const student = readField<RefObjectType>('student', incoming);

          toReference(
            {
              id: readField<string>('id', student),
              __typename: 'User',
              isViewer: true,
            },
            true,
          );

          return mergeObjects(existing, incoming);
        },
      },
      invitations: {
        merge: false,
      },
      video: {
        read(existing, { toReference, args }) {
          const { id } = args ?? {};
          return (
            existing ||
            toReference(
              {
                __typename: 'Video',
                id,
              },
              true,
            )
          );
        },
      },
      file: {
        read(existing, { toReference, args }) {
          const { id } = args ?? {};
          return (
            existing ||
            toReference(
              {
                __typename: 'File',
                id,
              },
              true,
            )
          );
        },
      },
      asset: {
        read(existing, { toReference, args }) {
          const { id } = args ?? {};
          const { typename: __typename } = args ?? {};
          return (
            existing ||
            toReference({
              __typename,
              id,
            })
          );
        },
      },
      configuration: {
        merge: true,
      },
      draft: {
        read(existing, { toReference, args }) {
          const { id } = args ?? {};
          return (
            existing ||
            toReference(
              {
                __typename: 'Draft',
                id,
                body: '',
                attachments: [],
              },
              true,
            )
          );
        },
      },
      orders: relayStylePagination(['filter', 'term']),
      redeemables: relayStylePagination(['filter', 'term']),
      classroom: {
        read(existing, { toReference, args }) {
          const id = Number(args?.id) ? btoa(`Classroom/${args?.id}`) : args?.id;
          return (
            existing ||
            toReference({
              __typename: 'Classroom',
              id,
            })
          );
        },
      },
    },
  },
  Basket: {
    fields: {
      items: {
        merge: false,
      },
    },
  },
  Draft: {
    fields: {
      attachments: {
        merge: false,
      },
    },
  },
  School: {
    fields: {
      files: {
        merge: true,
      },
    },
  },

  Seat: {
    fields: {
      classroom: {
        merge(existing, incoming, { mergeObjects, readField, toReference, canRead }) {
          const merged = mergeObjects(existing, incoming);
          if (!canRead(readField('seats', incoming))) return merged;
          resolveUserNames()(incoming, { readField, toReference });
          return merged;
        },
      },
    },
  },
  Subscription: {
    fields: {
      classroom: {
        merge: false,
      },
      classroomUpdated: {
        merge: false,
      },
      assignmentUpdated: {
        merge: false,
      },
    },
  },
  File: {
    fields: {
      progress: {
        read(progress = null) {
          return progress;
        },
      },
    },
  },
  Video: {
    fields: {
      progress: {
        read(progress = null) {
          return progress;
        },
      },
    },
  },
  Product: {
    fields: {
      files: {
        merge: true,
      },
      pricing: {
        merge: true,
      },
    },
  },
  Variant: {
    fields: {
      price: {
        merge: true,
      },
    },
  },
  User: {
    fields: {
      notifications: relayStylePagination(),
      online: {
        read(online) {
          return online || false;
        },
      },
      isClassroomTutor: {
        read(existing) {
          return !!existing;
        },
      },
      isModerator: {
        read(existing) {
          return !!existing;
        },
      },
      isStudent: {
        read(existing) {
          return !!existing;
        },
      },
      isViewer: {
        read(existing) {
          return !!existing;
        },
      },
      displayName: {
        read(_, { readField }) {
          const profile = readField<RefObjectType>('profile');
          if (profile) {
            const name = readField('hasNameClash')
              ? `${readField('firstName', profile) ?? ''} ${
                  readField('lastInitial', profile) ?? ''
                }`
              : readField('firstName', profile) ?? '';
            return name;
          }
          console.warn('Missing profile when attempting to calculate displayName');
          return '';
        },
      },
      fullName: {
        read(_, { readField }) {
          const profile = readField<RefObjectType>('profile');
          if (profile) {
            const fullName = readField('isClassroomTutor')
              ? readField('name', profile) ?? 'No name'
              : readField('displayName');

            return fullName;
          }
          console.warn('Missing profile when attempting to calculate fullName');
          return '';
        },
      },
      badgeLabel: {
        read(_, { readField }) {
          const isClassroomTutor = readField('isClassroomTutor');
          const isModerator = readField('isModerator');

          if (!isClassroomTutor && !isModerator) return '';
          return readField('isClassroomTutor') ? 'TUTOR' : readField('isModerator') ? 'STAFF' : '';
        },
      },
    },
  },
  Lesson: {
    fields: {
      isAvailable: {
        read(_, { readField }) {
          return new Date(readField('availableOn') ?? 0) <= new Date();
        },
      },
    },
  },
  Message: {
    fields: {
      slateBody: {
        merge: true,
      },
      comments: relayStylePagination(['targetId']),
    },
  },
  Management: {
    keyFields: false,
    merge: true,
    fields: {
      users: relayStylePagination(['filter', 'term']),
      courses: relayStylePagination(['filter', 'term']),
      classrooms: relayStylePagination(['filter', 'term']),
    },
  },
  PromoCode: {
    fields: {
      redemptions: relayStylePagination(),
    },
  },
  Course: {
    fields: {
      classrooms: relayStylePagination(),
      studentInvitations: relayStylePagination(['filter']),
      moderatorInvitations: relayStylePagination(['filter']),
      lessons: {
        merge(_, incoming = []) {
          return incoming;
        },
      },
    },
  },
  PrivateConversation: {
    fields: {
      ...TypingAware.fields,
      messages: relayStylePagination(),
    },
  },
  ClassroomMessage: {
    fields: {
      ...TypingAware.fields,
      slateBody: {
        merge: true,
      },
      //comments: relayStylePagination(),
    },
  },
  ClassroomMessageReply: {
    fields: {
      slateBody: {
        merge: true,
      },
      //comments: relayStylePagination(),
    },
  },
  ClassroomMessageReplyReply: {
    fields: {
      slateBody: {
        merge: true,
      },
      //comments: relayStylePagination(),
    },
  },
  AssignmentMessageReply: {
    fields: {
      slateBody: {
        merge: true,
      },
    },
  },
  AssignmentMessage: {
    fields: {
      ...TypingAware.fields,
      slateBody: {
        merge: true,
      },
      //comments: relayStylePagination(),
    },
  },
  Assignment: {
    fields: {
      ...TypingAware.fields,
      attachments: {
        merge: false,
      },
    },
  },
  Classroom: {
    fields: {
      ...TypingAware.fields,
      // seats: relayStylePagination(),
      feedV2: relayStylePagination(['targetId']),
      feed: {
        merge(existing = [], incoming = [], { args }) {
          if (!Array.isArray(incoming)) return existing;
          if (args && args.before) return [...existing, ...incoming];
          const refs = existing.map((item: RefObjectType) => item.__ref);
          const insertion = incoming.filter((item) => !refs.includes(item.__ref));
          return [...insertion, ...existing];
        },
      },
      moderators: {
        merge(existing = [], incoming, { toReference, readField }) {
          if (incoming) {
            incoming.forEach((moderator: RefObjectType) => {
              toReference(
                {
                  __typename: 'User',
                  id: readField('id', moderator),
                  isModerator: true,
                },
                true,
              );
            });
            return incoming;
          }

          return existing;
        },
      },
      tutor: {
        merge(existing, incoming, { toReference, readField, mergeObjects }) {
          toReference(
            {
              __typename: 'User',
              id: readField('id', incoming),
              isClassroomTutor: true,
            },
            true,
          );
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  LocalizedPriceRange: {
    keyFields: false,
    merge: true,
  },
  LocalizedPrices: {
    keyFields: false,
    merge: true,
  },
  LocalizedFormattedPrices: {
    keyFields: false,
    merge: true,
  },
  Prices: {
    keyFields: false,
    merge: true,
  },
  Price: {
    keyFields: false,
    merge: true,
  },
  FormattedPrice: {
    keyFields: false,
    merge: true,
  },
  FormattedPrices: {
    keyFields: false,
    merge: true,
  },
  Settings: {
    keyFields: false,
    merge: true,
  },
  Promotion: {
    keyFields: false,
    merge: true,
  },
  Features: {
    fields: {
      feature: {
        merge: true,
      },
    },
  },
  Project: {
    fields: {
      ...TypingAware.fields,
    },
  },
  ProjectSubmission: {
    fields: {
      ...TypingAware.fields,
      attachments: {
        merge(_, incoming = []) {
          return incoming;
        },
      },
      comments: relayStylePagination(),
    },
  },
};

export default typePolicies;
