import React, { useContext, useState } from 'react';
import { Page as BasePage, Box, Text, DropButton, Anchor, ThemeContext } from 'grommet';
import { Header, UserAvatar, LazyImage } from '@lwe/toolkit/ui-library';
import styled from 'styled-components';
import { useLogout, useCurrentUser } from '@lwe/toolkit/authentication';
import Footer from '../Footer';
import { Link } from 'gatsby';
import env from '@lwe/toolkit/env';
import { genAssetImgSrc } from '@lwe/toolkit/utils';
import { ExtendedThemeType, useIsMobile } from '@lwe/toolkit/theming';

const Image = styled(LazyImage)`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const desktop = (Comp) => styled(Comp)`
  @media only screen and (max-width: 400px) {
    display: none;
  }
`;

const mobile = (Comp) => styled(Comp)`
  @media only screen and (min-width: 400px) {
    display: none;
  }
`;

const MobileAnchor = mobile(Anchor);
const DesktopText = desktop(Text);
const DesktopAnchor = desktop(Anchor);

const Menu = () => {
  const logout = useLogout();

  return (
    <Box pad={{ horizontal: 'small' }}>
      <Anchor color="grey-7" size="small" onClick={logout} margin={{ vertical: 'xsmall' }}>
        Log out
      </Anchor>
      <MobileAnchor
        margin={{ vertical: 'xsmall' }}
        color="grey-7"
        size="small"
        href={`${env('CLASSROOM_URL')}/classrooms`}
      >
        My Courses
      </MobileAnchor>
    </Box>
  );
};

const Logo = () => {
  const isMobile = useIsMobile();
  const theme = useContext<ExtendedThemeType>(ThemeContext);

  const logoWidth = isMobile ? 210 : 250;
  const logoHeight = isMobile ? 32 : 48;
  const logoUuid = theme?.global?.logo?.web;

  return (
    <Box
      margin={{ left: isMobile ? '15px' : '22px' }}
      width={{ max: `${logoWidth}px` }}
      fill="vertical"
      justify="center"
      align="start"
    >
      <Link to="/">
        <Image height={`${logoHeight}px`} {...genAssetImgSrc(logoUuid, 400)} fade />
      </Link>
    </Box>
  );
};

const Page = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { currentUser } = useCurrentUser();

  return (
    <BasePage kind="full" background="white">
      <Header
        title="Optimal Ageing"
        background={['#878D06', '#D89206', '#D15A80']}
        width={{ width: '100%', max: '1440px' }}
        logo={<Logo />}
        sticky
      >
        <Box
          direction="row"
          justify="end"
          align="center"
          fill="vertical"
          gap="xsmall"
          pad={{ right: 'small' }}
        >
          <Text color="grey-1" size="small">
            <Link to="/">
              <DesktopText>Browse all</DesktopText> Courses
            </Link>
          </Text>
          {currentUser && (
            <DesktopAnchor color="grey-1" size="small" href={`${env('CLASSROOM_URL')}/classrooms`}>
              My Courses
            </DesktopAnchor>
          )}
          {currentUser && (
            <DropButton
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              dropContent={<Menu />}
              dropAlign={{ top: 'bottom', right: 'right' }}
              dropProps={{
                background: 'background-front',
                round: '3px',
              }}
              focusIndicator={false}
            >
              <UserAvatar user={currentUser} size="small" />
            </DropButton>
          )}
        </Box>
      </Header>
      {children}
      <Footer />
    </BasePage>
  );
};

export default Page;
