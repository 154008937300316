import React, { ImgHTMLAttributes, useCallback, useState } from 'react';
import styled from 'styled-components';
import useNativeLazyLoading from '@charlietango/use-native-lazy-loading';
import { useInView } from 'react-intersection-observer';

const Image = styled.img<{ fade?: boolean; loaded?: boolean }>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ fade, loaded }) => (fade ? (loaded ? 1 : 0) : 1)};
`;

export type ExtendedLazyImageProps = {
  eager?: boolean;
  fade?: boolean;
};

type LazyImageTProps = ImgHTMLAttributes<HTMLImageElement> & ExtendedLazyImageProps;

const LazyImage = ({ src, srcSet, eager, fade, onLoad, ...rest }: LazyImageTProps): JSX.Element => {
  const supportsLazyLoading = useNativeLazyLoading();
  const [loaded, setLoaded] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });

  const handleLoaded = useCallback(
    (e) => {
      setLoaded(true);

      if (onLoad) onLoad(e);
    },
    [setLoaded, onLoad],
  );

  return (
    <Image
      src={inView || supportsLazyLoading || eager ? src : undefined}
      srcSet={inView || supportsLazyLoading || eager ? srcSet : undefined}
      ref={supportsLazyLoading ? undefined : ref}
      onLoad={handleLoaded}
      loading={eager ? 'eager' : 'lazy'}
      fade={fade}
      loaded={loaded}
      {...rest}
    />
  );
};

export default LazyImage;
