import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { ThemeContext } from 'grommet';
import { ExtendedThemeType } from '@lwe/toolkit/theming';
import Helpscout from './components/Helpscout';

const Head = (): JSX.Element => {
  const theme = useContext<ExtendedThemeType>(ThemeContext);
  return (
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      {theme?.global?.font?.links?.map((item, index) => (
        <link key={index} {...item} />
      ))}
    </Helmet>
  );
};

export default Head;
