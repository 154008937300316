import React, { useEffect, useMemo, createContext, useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Head, Helpscout, PartnerThemeProvider } from '@lwe/toolkit/theming';
import { usePartnerTheme } from '@lwe/toolkit/utils';
import Page from '../components/Page';
import Notification from '../components/Notification';

const CREATE_RESELLER_END_USER = gql`
  mutation CreateResellerEndUser($token: String!, $resellerSlug: String!) {
    createResellerEndUser(token: $token, resellerSlug: $resellerSlug) {
      id
      maxClaims
      ref
      canClaim
      user {
        id
        profile {
          id
          firstName
        }
      }
    }
  }
`;

const defaultContextValue = {
  endUser: null,
};

const ResellerContext = createContext({
  endUser: null,
  loading: true,
  claim: (courseId: string) => void undefined,
});

export const useResellerTools = () => {
  return useContext(ResellerContext);
};

const Layout = ({ children }) => {
  const [createResellerEndUser, { data, loading, called }] = useMutation(CREATE_RESELLER_END_USER);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token') ?? sessionStorage.getItem('res-token');
    const resellerSlug = params.get('res') ?? sessionStorage.getItem('res-slug');
    if (token && resellerSlug) {
      sessionStorage.setItem('res-token', token);
      sessionStorage.setItem('res-slug', resellerSlug);
      createResellerEndUser({
        variables: {
          token,
          resellerSlug,
        },
      });
    }
  }, []);

  const theme = usePartnerTheme();

  const value = useMemo(() => {
    return {
      endUser: data?.createResellerEndUser,
      loading: loading || !called,
    };
  }, [data, loading, called]);

  const brokenLink = !loading && !data;
  const cantClaim = data && !data?.createResellerEndUser?.canClaim;

  return (
    <PartnerThemeProvider themeMode="light" theme={theme} full={false}>
      <Head />
      <Helpscout display="icon" />
      <ResellerContext.Provider value={value}>
        {brokenLink && (
          <Notification message="There's a problem with this link. Please contact your HR team." />
        )}
        {cantClaim && (
          <Notification message="No more claims can be made using this link. Please contact your HR team." />
        )}
        <Page>{children}</Page>
      </ResellerContext.Provider>
    </PartnerThemeProvider>
  );
};

export default Layout;
